var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.itemspatients,"server-items-length":_vm.totalpatient,"options":_vm.options,"item-key":"index"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.patientDoctor",fn:function(ref){
var item = ref.item;
return [(item.patientDoctor)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName(item.patientDoctor.fullname))}},[_vm._v(" "+_vm._s(_vm.getName(item.patientDoctor.fullname)))])]:_vm._e()]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createAt))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.id))+" ")]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.fullname))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"small":"","rounded":"","color":_vm.getColorStatus(item.status)}},[_vm._v(_vm._s(item.status))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }